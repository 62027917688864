@use "@odm/ui/dist/styles/layout";

$page-header-height: 129px;

.scrollable-content {
  max-height: calc(100vh - #{layout.$navbar-height});
  overflow: auto;
}

.page-header + .scrollable-content {
  max-height: calc(100vh - #{layout.$navbar-height} - #{$page-header-height});
}