@use "@odm/ui/dist/styles/layout";
@use "@odm/ui/dist/styles/typography";
@use "@odm/ui/dist/styles/colors";

.ride-order-timing-headline {
  display: flex;
  color: colors.$primary;
  font-weight: typography.$font-weight-medium;
  height: layout.$elem-spacing-md;
  align-items: center;
  justify-content: center;
}