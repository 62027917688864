@use "@odm/ui/dist/styles/layout";
@use "@odm/ui/dist/styles/colors";
@use "@odm/ui/dist/styles/typography";

h5.form-heading {

  text-transform: uppercase;
  color: colors.$text-muted;
  font-size: typography.$font-size-default;
  font-weight: bold;
  padding: layout.get-elem-spacing(5, 0, 3, 12);

}