.form-error-icon {
  display: flex;
  justify-content: center;
  align-content: center;
  padding-right: 8px;

  .icon-container {
    color: var(--odm-signal-danger);
  }

  svg {
    fill: var(--odm-signal-danger);
  }
}