.caution-wrapper {

  .actions {
    margin-left: auto;
  }

  h3 {
    font-weight: bold;
    color: #f7b731;
  }

  tr {
    background-color: #fffae8;
  }

  tr.message > td {
    margin: 8px;
    padding: 8px 10px;
    background-color: #f7b731;
    color: white;
    font-weight: bold;
  }

  .tooltip-anchor {
    position: relative;
    z-index: 100;
  }
}