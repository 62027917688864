@use "@odm/ui/dist/styles/colors";
@use "@odm/ui/dist/styles/layout";
@use "@odm/ui/dist/styles/typography";
@use "@odm/ui/dist/styles/animations";

.ride-order-table {

  .reference-ride-button .icon-container .icon-container-icon svg {
      color: colors.$text-muted;
  }

  .date-time-container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
    color:colors.$primary;
    font-size: typography.$font-size-default;

    :first-child {
      margin-right: layout.$elem-spacing-unit;
      flex-shrink: 0;
      font-size: layout.$icon-size-sm;
    }

    .date-time-text {
      display: none;
      font-size: typography.$font-size-default;
    }

    &.secondary {
      color:colors.$secondary;
    }
  }

  tr:has(+ tr.message-row) {
    border-bottom: none;
  }

  tr.message-row>td {
    padding-top: 0;
    padding-bottom: layout.get-baseline-spacing(2);

    >div{
      padding: 0;
    }
  }
  tr:has(+ tr.message-row:hover),
  tr:hover+.message-row {
    background-color: colors.$elem-hover;
  }

  .message {
    td {
      padding-top: 0;
    }

    &:hover {
      background-color: transparent;
    }

    .section-message {
      display: flex;
      align-items: center;
      gap: layout.get-baseline-spacing(1);
    }

    .section-message-container {
      padding: 0;
    }
  }

  @keyframes blink {
    0% {
      background-color: transparent;
    }
    50% {
      background-color: colors.$secondary-focus;
    }
    100% {
      background-color: transparent;
    }
  }
  
  .blink {
    animation: blink 1s 2;
  }

  @media screen and (min-width: layout.$breakpoint-lg-min) {
    table {
      table-layout: fixed;

      tr > th:nth-child(1) { width: 14% }
      tr > th:nth-child(2) { width: 14% }
      tr > th:nth-child(3) { width: 14% }
      tr > th:nth-child(4) { width: 10% }
      tr > th:nth-child(5) { width: 14% }
      tr > th:nth-child(6) { width: 14% }
      tr > th:nth-child(7) { width: 8% }
    }

    .date-time-container .date-time-text {
      display: inline;
    }
  }
}