@use "@odm/ui/dist/styles/colors";
@use "@odm/ui/dist/styles/layout";

$layout-background-subtle: rgb(250, 250, 250);

a:hover {
  color: colors.$primary;
  text-decoration: none;
}

li > a {
  display: block;
}

.app-content {
  background-color: $layout-background-subtle;
}

.page-header {
  background-color: colors.$layout-surface;
}

header > h3 {
  margin-bottom: layout.get-elem-spacing(4);

  > span {
    font-weight: bold;
  }
}