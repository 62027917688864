.oder-state-badge {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .icon-color-container {
    width: 24px;
    height: 24px;
    border-radius: 3px;

    margin-right: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .icon-color-container.sway > * {
    animation: swaying 3.5s ease-in-out forwards infinite;
  }

  .icon-color-container.flash > * {
    animation: flashing 1.5s ease-in-out forwards infinite;
  }
}

@keyframes swaying {
  0%{transform: translateX(-3px);}
  50%{transform: translateX(3px)}
  100%{transform: translateX(-3px);}
}

@keyframes flashing {
  0%{opacity: 0;}
  50%{opacity: 100%;}
  100%{opacity: 0;}
}