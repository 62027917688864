@use "@odm/ui/dist/styles/layout";
@use "@odm/ui/dist/styles/typography";
@use "@odm/ui/dist/styles/colors";

.ride-order-form {

  .icon-container :where(svg) {
    color: colors.$secondary
  }

  min-width: layout.$elem-spacing-xl*3;

  .add-note-btn-container {
    display: flex;
    justify-content: center;
  }

  form {
    display: flex;
    flex-direction: column;

    button[type="submit"] {
      width: auto;
      min-width: 50%;
      padding: layout.get-elem-spacing(6, 8);
      margin: layout.get-elem-spacing(4) auto;
    }

    .time-labels {

      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: row;
      margin: layout.get-elem-spacing(4, 0, 3, 12);
      padding: layout.get-elem-spacing(5);
      border-radius: layout.$border-radius-md;
      background-color: colors.$muted-faded;

      > .time-icon-container {
        margin-right: layout.get-elem-spacing(5);
      }

      > .time-label-container {

        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: left;

        .pickupLabel {
          color: colors.$text-muted;
        }

        .dateTimeLabel {
          font-size: 12pt;
          font-weight: bold;
          color: colors.$primary;
        }
      }
    }

    .time-labels:hover {
      background-color: colors.$layout-foreground;
    }
  }

  form.submitting {
    .icon-container-icon>svg {
      color: colors.$layout-accent;
    }
  }

  .icon-button.invalid {
    span, svg {
      color: colors.$signal-danger
    }
  }

}