@use "@odm/ui/dist/styles/typography";
@use "@odm/ui/dist/styles/layout";
@use "@odm/ui/dist/styles/colors";

.cm-ride-type-icon {
  display: flex;
  position: relative;

  .cm-ride-type-icon__infected{
    position: absolute;
    right: 0;
    z-index: 1;
    color: colors.$secondary;
    transform: translate(50%, -50%);
    border-radius: 50%;
    background-color: colors.$layout-surface;
    width: layout.$icon-size-md;
    height: layout.$icon-size-md;

    svg {
      width: layout.$icon-size-md;
      height: layout.$icon-size-md;

    }
  }
  .cm-ride-type-icon__icon{
    stroke: currentColor;
    fill: currentColor;
    font-weight: typography.$font-weight-light;
    color: colors.$secondary;
    height: 1em;
    width: 1em;
  }
}