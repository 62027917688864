@use "@odm/ui/dist/styles/elements";
@use "@odm/ui/dist/styles/colors";
@use "@odm/ui/dist/styles/layout";

@include elements.css-resets;

body {
  margin: 0;
  min-height: 100vh;

  font-family: Poppins,Arial,sans-serif;
  font-weight: 400;
  color: var(--odm-text-default);
  background: var(--odm-layout-surface);

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.clickable {
  cursor: pointer;
}

:root {
  @include colors.set-custom-properties;
  --odm-font-family: Poppins,Arial,sans-serif;
}

.ReactModal__Overlay {
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0,0,0,.2) !important;
  backdrop-filter: blur(2px);
  overflow-y: auto;

  .ReactModal__Content {
    inset: auto !important;
    padding: 0 !important;
    width: clamp(#{layout.$elem-spacing-xl*5},#{layout.$elem-spacing-xl*5},100%)
  }
}

.Toastify__toast-container {
  z-index: 200000 !important;
}