@use "@odm/ui/dist/styles/layout";
@use "@odm/ui/dist/styles/colors";

.ride-order-type-section {
  .button-section {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    flex-wrap: wrap;
    position: relative;
    gap: layout.get-elem-spacing(1);
    margin-left: layout.get-elem-spacing(12);
    margin-bottom: layout.get-elem-spacing(8);
  
    > * {
      width: calc(33% - 2px);
      flex-basis: calc(33% - 2px);
      height: unset;
  
      .icon-container {
        width: 100%;
        align-self: baseline;
    
        span {
          width: 100%;
          word-break: break-word;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 3;
                  line-clamp: 3; 
          -webkit-box-orient: vertical;
        }
      }
    }
  
    > *.active:hover {
      background-color: colors.$primary-faded;
    }
  }
  
}
