@use "@odm/ui/dist/styles/layout";
@use "@odm/ui/dist/styles/colors";
footer {
  display: flex;
  padding: layout.get-baseline-spacing(4);
  align-items: center;
  justify-content: center;
  flex-flow: column;

  .legal {
    display: flex;
    gap: layout.$baseline-spacing-unit;
    margin: layout.get-baseline-spacing(1,0);
    align-items: center;
    justify-content: center;

    a {
      color: colors.$primary;

      &:hover {
        color: colors.$primary-hover;
      }
    }

    a:not(:last-child):after {
      content: "|";
      margin: 0 layout.get-elem-spacing(2);
    }
  }
}