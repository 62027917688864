@use "@odm/ui/dist/styles/colors.scss";


.ride-order-comment-form-section {
  .icon-container-icon>svg {
    color: colors.$secondary;
  }
  .comment-character-count{
    font-size: 0.8em;
    text-align: right;
  }
}