@use "@odm/ui/dist/styles/layout";
@use "@odm/ui/dist/styles/colors";
@use "@odm/ui/dist/styles/typography";

$min-hero-header-height: layout.$elem-spacing-xl * 4;
$hero-header-height: calc(100% - #{layout.$elem-spacing-xl});

.hero-header {

  position: relative;
  min-height: $min-hero-header-height;
  display: flex;
  flex-wrap: wrap;
}

.hero-header_content {
  flex: 1 0 50%;

  >.card {
    position: relative;
    margin: layout.get-baseline-spacing(4);
  }

  .order-heading {
    font-size: typography.$font-size-h-sm;
    color: colors.$text-dark;
    font-weight: bold;
    padding: layout.get-elem-spacing(5, 0, 0, 15);
    text-align: left;
  }
}

.hero-header_content>.card>.h-txt {
  margin: layout.get-baseline-spacing(1,0,2,0);
}

.hero-header_header {
  max-height: $hero-header-height;
  flex: 1 0 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 1;
}

.hero-header_header>h1 {
  font-size: 23pt !important;
  font-weight: bold;
}

.hero-header_header>p {
  max-width: 50%;
}

.hero-header_header>.h-txt,
.hero-header_header>.d-txt {
  margin-inline: layout.get-baseline-spacing(4);
  color: colors.$text-contrast;
}

.hero-header_image {
  height: $hero-header-height;
  position: absolute;
  inset-inline: 0;
  display: block;
}

.hero-header_image>img {
  height: 100%;
  width:100%;
  object-fit: cover;
}

.hero-header_image:after {
  content: "";
  position: absolute;
  inset: 0;
  background-image: linear-gradient(to right, colors.$secondary , transparent);
}