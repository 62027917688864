@use "@odm/ui/dist/styles/colors";
@use "@odm/ui/dist/styles/layout";
@use "@odm/ui/dist/styles/typography";


.warning-container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
    color:colors.$signal-danger;
    font-size: typography.$font-size-default;

    :first-child {
      margin-right: layout.$elem-spacing-unit;
      flex-shrink: 0;
      font-size: layout.$icon-size-sm;
    }
}