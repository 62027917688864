@use "@odm/ui/dist/styles/layout";
@use "@odm/ui/dist/styles/colors";
@use "@odm/ui/dist/styles/typography";


.place-input-container {
  padding-top: layout.$elem-spacing-xs;
  position: relative;


  .controls {
    height: layout.$elem-spacing-sm;
    transition: height linear 200ms;

    box-sizing: content-box;
  }
  &.expanded .controls {
    height: layout.$elem-spacing-sm * 3;
    &:not(:focus-within) {
      overflow-y: hidden;
    }

    &:has(input[name$="placeComment"]){
      height: layout.$elem-spacing-sm * 4;
    }
  }

  .error-message {
    display: inline-block;
    font-size: typography.$font-size-sm;
    color: colors.$signal-danger;
  }

  .actions {
    position: absolute;
    right: layout.get-baseline-spacing(1);
    top: 0;
    height: layout.$elem-spacing-sm;
    align-items: center;
  }

  .actions button {
    padding: 0;
    margin: 0;
  }

  .actions {
    gap: layout.get-elem-spacing(1);

    .icon-button {
      padding: 0;
      margin-right: layout.get-elem-spacing(3);
    }
  }

  .actions button {
    height: layout.$elem-spacing-xs
  }
  .actions>button:last-child {
    margin-left: auto;
  }
  input[name$="placeDescription"] {
    padding-right: layout.get-baseline-spacing(15);
  }
  &.expanded .controls {
    display: grid;
    grid-template-columns: 1fr 1fr;

    border: layout.$border-width-default solid colors.$layout-separator;
    border-radius: layout.$border-radius-default;

    input {
      border: none;
      border-radius: 0;
    }

    .status-indicator {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }






    div:has(input[name$="street"]),
    div:has(input[name$="placeDescription"]),
    div:has(input[name$="placeComment"]) {
      grid-column: 1 / 3;
    }

    input[name$="street"],
    input[name$="placeDescription"],
    input[name$="placeComment"]{
      border-bottom: layout.$border-width-default solid colors.$layout-separator;
    }

    input[name$="city"] {
      border-left: layout.$border-width-default solid colors.$layout-separator;
    }

  }

}
