@use "node_modules/@odm/ui/dist/styles/layout";
@use "node_modules/@odm/ui/dist/styles/typography";
@use "node_modules/@odm/ui/dist/styles/colors";

.ride-order-dialog-form-section {

  .icon-container-icon>svg:not(.section-message.warning svg) {
    color: colors.$secondary;
  }

  .icon-section-timing {
    cursor: pointer;

    small {
      height: layout.$elem-spacing-sm;
      line-height: layout.$elem-spacing-sm;
    }
  }

  .icon-section {
    display: flex;
    align-items: flex-start;
    margin-bottom: layout.get-baseline-spacing(1);
    min-height: layout.$elem-spacing-sm;
    width: 100%;

    >*:nth-child(2) {
      flex-grow: 1;
    }

    .icon-container:first-of-type {
      min-width: layout.$elem-spacing-md;
      height: layout.$elem-spacing-sm;
    }

    .icon-container:last-of-type:not(:first-of-type) {
      svg {
        color: colors.$text-default
      }
    }
  }

  .timing-section {
    border-bottom: layout.$border-width-default solid colors.$layout-separator;
    padding-block: layout.get-baseline-spacing(1);
    margin-bottom: layout.get-baseline-spacing(1);
  }

  .ride-order-text-area-form-section {
    margin-top: layout.$elem-spacing-xs;
  }
}