@use "@odm/ui/dist/styles/layout";
@use "@odm/ui/dist/styles/colors";

.ride-provider-sidebar {
  border-right: layout.$border-width-default solid colors.$layout-separator;
  max-width: layout.$sidebar-base-width;
  background-color: colors.$layout-surface;
  max-height: 100%;
  overflow: scroll;

  >.content-container {
    padding-inline: 0 layout.get-baseline-spacing(3);
  }

  ul {
    padding-left: layout.get-baseline-spacing(3);
  }

  li {
    padding-bottom: layout.get-elem-spacing(6);
  }

  .ride-orderer-list {
    padding-left: 0;
    li {
      padding-bottom: 0;
    }
  }

  .h-txt {
    margin-bottom: layout.get-elem-spacing(3);
    display: block;
  }

  a {
    color: colors.$primary;
    padding: layout.get-elem-spacing(2, 3);
    margin: layout.get-elem-spacing(1, 0);
    border-radius: layout.$border-radius-default;

    &:hover, &:focus {
      background-color: colors.$layout-foreground;
      color: colors.$secondary;
    }
  }

  .active {
    background-color: colors.$layout-foreground;
    font-weight: bold;
    color: colors.$primary-focus;
  }
}